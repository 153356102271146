<template>
  <section class="app-ecommerce-details">
    <!-- Content -->
    <b-overlay :show="!ready || !cartReady" variant="dark" opacity="0.85" blur="2px" rounded="sm">
      <b-card v-if="ready && cartReady" no-body class="overflow-hidden">
        <b-card-body>
          <div class="product-is-new bg-light-success rounded" v-if="product.is_new_product === 1" v-html="$t('shop.is_new')" />
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="12" md="5" class="mb-2 mb-md-0">
              <b-overlay :show="getProductImages().length === 0" variant="dark" opacity="0.85" blur="2px" rounded="sm">
                <swiper class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-if="getProductImages().length > 1">
                  <swiper-slide v-for="(data, index) in getProductImages()" :key="index">
                    <div class="product-image-div">
                      <b-img class="product-image" :src="data" fluid />
                    </div>
                  </swiper-slide>

                  <!-- Add Arrows -->
                  <div slot="button-next" class="swiper-button-next text-primary" />
                  <div slot="button-prev" class="swiper-button-prev text-primary" />
                </swiper>
                <div class="d-flex justify-content-center align-items-center" v-else>
                  <b-img class="product-image" :src="getProductImages()[0]" fluid />
                </div>
              </b-overlay>
            </b-col>

            <!-- Right: Product Details -->
            <b-col cols="12" md="7">
              <!-- Product Name -->
              <h4>{{ `${product.product_name} ${!!product.attribute_name ? `(${product.attribute_name})` : ''}` }}</h4>

              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.barcode_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.barcode }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.product_code_tooltip') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.product_code }}
                </h5>
              </b-card-text>
              <br />
              <b-card-text class="item-company mb-0">
                <span>{{ $t('shop.unit') }}: </span>
                <h5
                  class="
                    company-name
                    mb-0
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  {{ product.unit_code }}
                </h5>
              </b-card-text>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap" v-if="product.is_discount === 1 && product.disc_amt > 0">
                <h5 class="text-muted discount">{{ productPriceOld() }}</h5>
              </div>
              <div class="ecommerce-details-price d-flex flex-wrap mt-1" v-if="product.unit_price_tra > 0">
                <h3>{{ productPrice() }}</h3>
              </div>
              <div class="ecommerce-details-price d-flex flex-wrap" v-if="product.unit_price_tra > 0">
                <h3 class="item-price text-warning" v-b-tooltip.hover.v-warning.html.bottom :title="priceTooltip()">( {{ productPriceTL() }} )</h3>
              </div>

              <!-- Avability -->
              <b-card-text v-if="product.in_stock === 1 && product.unit_price_tra > 0">
                <span class="text-success">
                  {{ $t('shop.avaible_in_stock') }}
                </span>
              </b-card-text>
              <b-card-text v-else>
                <span class="text-danger">
                  {{ $t('shop.out_of_stock') }}
                </span>
              </b-card-text>

              <!-- Product Meta [Free shpping, EMI, etc.] -->
              <ul class="product-features list-unstyled">
                <li>
                  <span></span>
                </li>
              </ul>

              <hr />

              <div class="d-flex flex-column flex-sm-row pt-1">
                <!-- <b-dropdown
                  variant="outline-secondary"
                  no-caret
                  toggle-class="btn-icon"
                  class="btn-share mr-1"
                  left
                >
                  <template #button-content>
                    <feather-icon icon="Share2Icon" />
                  </template>
                  <b-dropdown-item v-for="item in socials" :key="item.icon">
                    <feather-icon :icon="item.icon" />
                    <span class="ml-1">{{ item.name }}</span>
                  </b-dropdown-item>
                </b-dropdown> -->
                <b-button variant="outline-secondary" class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0" @click="toggleProductInWishlist(product)">
                  <feather-icon icon="BookmarkIcon" class="mr-50" />
                  <span>{{ $t('shop.wish_list') }}</span>
                </b-button>
                <div v-if="product.qty_avaible > 0 && product.unit_price_tra > 0" class="quantity-area">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0" @click="handleCartActionClick(product)" :disabled="cartButtonState" v-if="!checkInCart(product.product_id)">
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>
                      {{ $t('shop.add_to_cart') }}
                    </span>
                  </b-button>
                  <change-quantity v-else :productId="product.product_id" :maxAmount="product.qty_avaible < product.qty_order_max ? product.qty_avaible : product.qty_order_max" />
                </div>
                <div v-else>
                  <b-button
                    variant="outline-warning"
                    class="
                      btn-wishlist
                      mr-0 mr-sm-1
                      mb-1 mb-sm-0
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                    @click="notAvaible()"
                  >
                    <feather-icon icon="PhoneIcon" size="20" class="mr-50" />
                    <span v-html="$t('shop.not_avaible')" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

        <!-- Static Content -->
        <features :features="product.product_description" />

        <!-- Static Content -->
        <!-- Slider: Related Products -->
        <!-- <related-products /> -->
      </b-card>
    </b-overlay>
  </section>
</template>

<style lang="scss">
.product-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.product-image {
  max-height: 400px !important;
}
</style>

<script>
import { BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Features from './Features.vue'
import RelatedProducts from './RelatedProducts.vue'
import ChangeQuantity from '@/views/Order/Shop/ChangeQuantity.vue'
import { convertTL, priceFormat, priceTooltip, priceWithVat } from '@/assets/js/functions/currency-functions'
import { GET_PRODUCT } from '@/store/services/shop-service'
import { PUSH_BREADCRUMB } from '@/store/services/breadcrumb-service'
import { mapGetters } from 'vuex'
import { ADD_CART_EVENT } from '@/store/services/checkout-service'

export default {
  name: 'product-detail',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BOverlay,
    VBTooltip,
    Swiper,
    SwiperSlide,
    // SFC
    Features,
    RelatedProducts,
    ChangeQuantity
  },
  data() {
    return {
      ready: false,
      product: {},
      images: [],
      socials: [
        { icon: 'FacebookIcon', name: 'Facebook' },
        { icon: 'TwitterIcon', name: 'Twitter' },
        { icon: 'YoutubeIcon', name: 'Youtube' },
        { icon: 'InstagramIcon', name: 'Instagram' }
      ],
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      title: '',
      cartButtonState: false
    }
  },
  created() {
    this.getProduct()
  },
  computed: {
    ...mapGetters(['cart', 'cartReady', 'wishlist', 'productImages', 'currency', 'getLocale', 'brandLogo', 'brandTitle']),
    origin() {
      return this.product.origin?.toLocaleUpperCase('tr')
    },
    product_id() {
      return this.$route.params.id * 1
    }
  },
  methods: {
    getProduct() {
      this.ready = false
      this.$store.dispatch(GET_PRODUCT, this.product_id).then(response => {
        this.product = response[0]
        this.title = `${response[0].product_name} - ${this.brandTitle}`
        document.title = this.title
        this.$store.commit(PUSH_BREADCRUMB, response[0].product_name)
        this.ready = true
      })
    },
    getProductImages() {
      let tempImages = JSON.parse(this.product?.product_images)
      let showImages = []
      if (tempImages?.length) {
        tempImages.forEach(item => {
          showImages.push(`https://${process.env.VUE_APP_API_HOST}/Image/GetImage?image_id=${item.image_id}`)
        })
      } else {
        showImages.push(this.brandLogo)
      }
      return showImages
    },
    productPriceOld() {
      let price = this.product.disc_amt
      let type = this.product.currency
      return priceFormat(price, this.getLocale, type)
    },
    productPrice() {
      let price = this.product.unit_price_tra
      price = priceWithVat(price, this.product.vat_rate)
      let type = this.product.currency
      return priceFormat(price, this.getLocale, type)
    },
    productPriceTL() {
      let price = this.product.unit_price_tra
      let rate = this.product.vat_rate
      let type = this.product.currency
      price = convertTL(price, type, this.currency)
      price = priceWithVat(price, rate)
      return priceFormat(price, this.getLocale, 'TRY')
    },
    priceTooltip() {
      let price = this.product.unit_price_tra
      let rate = this.product.vat_rate
      let type = this.product.currency
      let tooltip = priceTooltip(price, rate, type, this.currency, this.getLocale)

      let html = `<h4 class="text-white">${this.$t('shop.price_detail')}</h4>
        <table>`
      if (type === 'USD') {
        html += `
        <tr>
          <td class="text-left">
            <h5 class="text-white text-nowrap">USD ${this.$t('shop.total')}</h5>
          </td>
          <td class="text-right">
            <h4 class="text-white text-nowrap">${tooltip.LIST_SHOW}</h4>
          </td>
        </tr>`
      }
      html += `
          <tr>
            <td class="text-left">
              <h5 class="text-white text-nowrap">TRY ${this.$t('shop.total')}</h5>
            </td>
            <td class="text-right">
              <h4 class="text-white text-nowrap">${tooltip.TRY_SHOW}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left border-top" style="border-width:2px!important;padding-top:5px;border-color:white!important;">
              <h5 class="text-white text-nowrap">${this.$t('shop.vat')} (%${rate})</h5>
            </td>
            <td class="text-right border-top" style="border-width:2px!important;padding-top:5px;border-color:white!important;">
              <h4 class="text-white text-nowrap">${tooltip.TRY_VAT_SHOW}</h4>
            </td>
          </tr>
          <tr>
            <td class="text-left">
              <h5 class="text-white text-nowrap">${this.$t('shop.vat_included')}</h5>
            </td>
            <td class="text-right">
              <h4 class="text-white text-nowrap">${tooltip.TRY_PWV_SHOW}</h4>
            </td>
          </tr>
        </table>`
      return html
    },
    checkInCart(id) {
      return this.cart.findIndex(x => x.product_id === id) !== -1
    },
    handleCartActionClick(product) {
      this.cartButtonState = true
      this.$store
        .dispatch(ADD_CART_EVENT, {
          product_id: product.product_id,
          price_list_detail_id: product.price_list_d_id,
          quantity: 1
        })
        .finally(() => {
          this.cartButtonState = false
        })
    },
    toggleProductInWishlist(product) {
      //this.$store.commit(TOGGLE_WISHLIST, product);
    },
    notAvaible() {
      this.$swal({
        text: this.$t('shop.not_avaible'),
        html: this.$t('shop.not_avaible_html'),
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-info'
        },
        confirmButtonText: this.$t('payment.error_button_text'),
        buttonsStyling: false
      })
    }
  },
  watch: {
    product_id() {
      this.getProduct()
    },
    getLocale() {
      document.title = this.title
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
//@import "@core/scss/vue/libs/swiper.scss";
@import '~swiper/css/swiper.css';
</style>

<style lang="scss" scoped>
.img-fluid {
  max-width: 500px !important;
}

.product-is-new {
  display: flex;
  position: absolute;
  top: 0.8rem;
  left: -5.2rem;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  width: 200px;
  justify-content: center;
  transform: rotateZ(-45deg);
  font-size: 9px;
  line-height: 11px;
  font-weight: 900;
  padding: 3px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.discount {
  text-decoration: line-through;
}

.quantity-area {
  width: 13rem;
}
</style>
