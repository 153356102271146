<template>
  <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm" class="h-100">
    <b-input-group class="change-quantity">
      <b-input-group-prepend v-if="others" class="d-flex justify-content-center align-items-center cursor-pointer" @click="decreaseQuantity()">
        <div class="input-group-text border-primary">
          <feather-icon :icon="product.quantity === '1' ? 'TrashIcon' : 'MinusIcon'" :class="{ 'text-danger': product.quantity === '1' }" size="22" />
        </div>
      </b-input-group-prepend>
      <cleave
        :id="`${idPrefix}_${productId}`"
        ref="input"
        v-model="product.quantity"
        :options="cleaveOptions"
        class="form-control h-auto border-primary"
        :class="{ rounded: !others }"
        @focus.native="focusQuantity = true"
        @keyup.native="quantityInput"
        @keyup.native.enter="changeQuantity()"
        @keydown.tab.prevent="changeQuantity()"
      />
      <b-input-group-append v-if="others" class="d-flex justify-content-center align-items-center cursor-pointer">
        <div class="input-group-text border-primary" v-if="focusQuantity" @click="changeQuantity()">
          <feather-icon icon="CheckCircleIcon" size="22" class="text-success" />
        </div>
        <div class="input-group-text border-primary" v-else @click="increaseQuantity()">
          <feather-icon icon="PlusIcon" size="22" />
        </div>
      </b-input-group-append>
    </b-input-group>
  </b-overlay>
</template>

<style lang="scss">
.change-quantity {
  height: 100%;
  min-height: 3rem;
  .form-control {
    text-align: center;
  }
  .input-group-prepend,
  .input-group-append {
    height: 100%;
    min-height: 3rem;
    .input-group-text {
      height: 100%;
      min-height: 3rem;
    }
  }
}

.grid-view {
  .change-quantity {
    .input-group-prepend,
    .input-group-append {
      .input-group-text {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
</style>

<script>
import { BInputGroup, BInputGroupAppend, BInputGroupPrepend, BOverlay } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { mapGetters } from 'vuex'
import { REMOVE_CART_EVENT, UPDATE_CART_EVENT } from '@/store/services/checkout-service'
import { SET_NOTIFICATION } from '@/store/services/module-service'

export default {
  name: 'change-quantity',
  props: {
    productId: { required: true },
    others: { required: false, default: true },
    maxAmount: { required: false, default: 1 },
    idPrefix: { required: false, default: 'cart_item' }
  },
  emits: ['changeQuantity'],
  components: {
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BOverlay,

    Cleave
  },
  data() {
    return {
      product: undefined,
      ready: false,
      focusQuantity: false
    }
  },
  created() {
    this.product = this.cart.find(x => x.product_id === this.productId)
    this.ready = true
  },
  computed: {
    ...mapGetters(['cart', 'getLocale']),
    cleaveOptions() {
      switch (this.getLocale) {
        case 'tr-TR':
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: ',',
            delimiter: '.'
          }
        default:
          return {
            numeral: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 0,
            numeralDecimalMark: '.',
            delimiter: ','
          }
      }
    }
  },
  methods: {
    changeQuantity() {
      let quantity = this.product.quantity * 1
      if (quantity <= 0) {
        this.removeItemFromCart()
      } else {
        if (quantity > this.maxAmount) {
          quantity = this.maxAmount
          this.product.quantity = this.maxAmount
        }
        this.$store.dispatch(UPDATE_CART_EVENT, {
          product_id: this.product.product_id,
          price_list_detail_id: this.product.price_list_d_id,
          quantity,
          cart_id: this.product.cart_id
        })
      }
      this.focusQuantity = false
      this.$emit('changeQuantity')
    },
    removeItemFromCart() {
      this.$store.dispatch(REMOVE_CART_EVENT, {
        product_id: this.product.product_id,
        price_list_detail_id: this.product.price_list_d_id,
        quantity: this.product.quantity * 1,
        cart_id: this.product.cart_id
      })
    },
    decreaseQuantity() {
      this.product.quantity--
      this.changeQuantity()
    },
    increaseQuantity() {
      this.product.quantity++
      this.changeQuantity()
    },
    quantityInput() {
      let q = this.product.quantity * 1
      let max = this.maxAmount
      if (q > max) {
        let temp = {
          show: true,
          type: 1,
          title: this.$t('notifications.quantity_warning_title'),
          message: this.$t('notifications.quantity_warning_max_message', { quantity: max }),
          variant: 'warning'
        }
        this.$store.commit(SET_NOTIFICATION, temp)
        this.product.quantity = max
        return false
      }
    }
  }
}
</script>
