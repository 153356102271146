<template>
  <div class="item-features">
    <div class="row">
      <div class="col-12 col-md-4 mb-4 mb-md-0 p-3" v-html="features">
        <div class="w-75 mx-auto"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-features",
  props: {
    features: { required: true, type: String },
  },
};
</script>

<style>
</style>
